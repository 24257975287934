import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { Module } from 'vuex';
import { nanoid } from 'nanoid';
import { router } from '@/router';
import { firestoreAction } from 'vuexfire';
import { PresenterPoll } from '@/api/interfaces.api';
import PresenterSession from '@/dtos/session';
import Vue from 'vue';
import ThemeModel from '@/dtos/theme';

export interface PresenterState {
  sessions: PresenterSession[];
  unlimitedSessions: PresenterSession[];
  shareURL?: string;
  currentSessionId?: string;
  currentSession?: PresenterSession;
  timelineData?: any[];
  sessionPolls: PresenterPoll[];
  pollCategories: any[];
  sliderAnswers: any[];
  choicesAnswers: any[];
  wordcloud: any;
  sessionLabels: { [id: string]: string };
  sessionDescriptions: { [id: string]: string };
  sessionButtonLabels: { [id: string]: string };
  sessionThemes: ThemeModel[];
  loading: boolean;
  reactions: any;
}

const PresenterModule: Module<PresenterState, any> = {
  namespaced: true,
  state: {
    sessions: [],
    unlimitedSessions: [],
    shareURL: '',
    currentSessionId: undefined,
    currentSession: undefined,
    timelineData: [],
    sessionPolls: [],
    pollCategories: [],
    sliderAnswers: [],
    choicesAnswers: [],
    wordcloud: {},
    sessionLabels: {},
    sessionDescriptions: {},
    sessionButtonLabels: {},
    sessionThemes: [],
    loading: true,
    reactions: {},
  } as PresenterState,
  getters: {
    getSessions: (state) => {
      if (state.sessions && state.sessions.length > 0) {
        const res = state.sessions.filter(
          (session: any) => !session.sessionUnlimited
        );
        return res;
      }
      return [];
    },
    getUnlimitedSessions: (state) => state.unlimitedSessions,
    getShareURL: (state) => state.shareURL,
    getCurrentSession: (state) => state.currentSession || {},
    getSessionShareToken: (state) => state.currentSession?.shareToken || '',
    getPastSessions: (state) => {
      if (state.sessions && state.sessions.length > 0) {
        const res = state.sessions.filter(
          (session: any) => !session.sessionUnlimited
        );
        return res;
      }
      return [];
    },
    getTimelineData: (state) => state.timelineData,
    getSessionPolls: (state) => state.sessionPolls,
    getPollById: (state) => (pollId: string) =>
      state.sessionPolls.filter((poll) => poll.id === pollId)[0],
    getSliderAnswers: (state) => state.sliderAnswers,
    getChoicesAnswers: (state) => state.choicesAnswers,
    getWordcloud: (state) => state.wordcloud,
    getCurrentPoll: (state) => (pollId: string) =>
      state.sessionPolls.filter((poll: PresenterPoll) => poll.id === pollId),
    getLabels: (state) => state.sessionLabels,
    getDescriptions: (state) => state.sessionDescriptions,
    getButtonLabels: (state) => state.sessionButtonLabels,
    getSessionThemes: (state) =>
      state.sessionThemes.map((theme: ThemeModel) => theme.name),
    getPollCategories: (state) => state.pollCategories,
    getLoadingState: (state) => state.loading,
    getReactions: (state) => state.reactions,
  },
  mutations: {
    SET_SHARE_URL(state, payload) {
      state.shareURL = payload;
    },
    SET_CURRENT_SESSION_ID(state, payload) {
      state.currentSessionId = payload;
    },
    SET_CURRENT_SESSION(state, payload) {
      state.currentSession = payload;
    },
    SET_SESSION_LABEL(state, { label, labelId }) {
      Vue.set(state.sessionLabels, labelId, label);
    },
    SET_SLIDERS_ANSWERS(state, answers) {
      Vue.set(state, 'sliderAnswers', answers);
    },
    SET_CHOICES_ANSWERS(state, answers) {
      Vue.set(state, 'choicesAnswers', answers);
    },
    SET_LOADING_STATE(state, loading) {
      Vue.set(state, 'loading', loading);
    },
    REMOVE_SESSION_LABEL(state, labelId) {
      delete state.sessionLabels[labelId];
    },
    SET_SESSION_DESCRIPTION(state, { description, descriptionId }) {
      Vue.set(state.sessionDescriptions, descriptionId, description);
    },
    REMOVE_SESSION_DESCRIPTION(state, descriptionId) {
      delete state.sessionDescriptions[descriptionId];
    },
    SET_SESSION_BUTTON_LABEL(state, { buttonLabel, buttonId }) {
      Vue.set(state.sessionButtonLabels, buttonId, buttonLabel);
    },
    REMOVE_SESSION_BUTTON_LABEL(state, buttonId) {
      delete state.sessionButtonLabels[buttonId];
    },
    RESET(state) {
      state.sessions = [];
      state.unlimitedSessions = [];
      state.shareURL = '';
      state.currentSessionId = undefined;
      state.currentSession = undefined;
      state.timelineData = [];
      state.sessionPolls = [];
      state.wordcloud = [];
      state.sessionLabels = {};
      state.sessionDescriptions = {};
      state.sessionButtonLabels = {};
      state.loading = false;
      state.reactions = {};
    },
  },
  actions: {
    async createSession({ commit, rootGetters }, { form, callback }) {
      const {
        name,
        description,
        startAt,
        endAt,
        sessionUnlimited,
        theme,
      } = form;
      const userData = rootGetters['auth/getUser'];
      const { uid, displayName } = userData.data;
      const isSuperUser = !!userData.isSuperUser;
      const userGroup = isSuperUser ? theme : userData.group;
      const newSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link: '',
        description,
        author: uid,
        authorName: displayName,
        userGroup,
      });

      // Random generate nanoid for share URL
      let shareToken = nanoid(8);
      while (shareToken.includes('I') || shareToken.includes('l')) {
        shareToken = nanoid(8);
      }
      const shareURL =
        'https://' + window.location.host + '/join/' + shareToken;
      newSession.shareToken = shareToken;

      const req = { newSession: newSession.toJSON(), uid, shareURL };
      const res: Response = await fetch('/api/createSession', {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      commit('SET_SHARE_URL', shareURL);
      commit('SET_CURRENT_SESSION_ID', resJSON.id);
      commit('SET_CURRENT_SESSION', newSession);
      callback();
    },

    // duplicate here
    async duplicateSession(
      { commit, rootGetters, getters },
      { form, isViewerData, callback }
    ) {
      const oldSession = getters['getCurrentSession'];
      const oldSessionId = oldSession.id;

      const {
        name,
        description,
        startAt,
        endAt,
        sessionUnlimited,
        userGroup,
      } = form;
      const userData = rootGetters['auth/getUser'];
      const { uid, displayName } = userData.data;

      const newSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link: '',
        description,
        author: uid,
        authorName: displayName,
        userGroup,
      });
      if (isViewerData) {
        newSession.reactions = oldSession['reactions'];
        newSession.wordcloudTopic = oldSession['wordcloudTopic'];
      }

      // Random generate nanoid for share URL
      let shareToken = nanoid(8);
      while (shareToken.includes('I') || shareToken.includes('l')) {
        shareToken = nanoid(8);
      }
      const shareURL = 'https://sterntv.reaction.link/j/' + shareToken;
      newSession.shareToken = shareToken;
      const newSessionJSON = newSession.toJSON();

      const req = {
        newSession: newSessionJSON,
        oldSessionId,
        isViewerData,
        uid,
        shareURL,
      };
      const res: Response = await fetch('/api/duplicateSession', {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      commit('SET_SHARE_URL', shareURL);
      commit('SET_CURRENT_SESSION_ID', resJSON.id);
      commit('SET_CURRENT_SESSION', newSession);
      callback();
    },

    async uploadFile(_, { author, folderName, fileName, file, sessionId }) {
      // Upload file and metadata to the object
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child('session-images')
        .child(author)
        .child(sessionId)
        .child(folderName)
        .put(file);

      // Listen for state changes, errors, and completion of the upload.
      await uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            const db = firebase.firestore();
            db.collection('sessions')
              .doc(sessionId)
              .set({ [fileName]: downloadURL }, { merge: true });
          });
        }
      );
    },
    async updateSession({ getters }, form) {
      const {
        name,
        description,
        startAt,
        endAt,
        link,
        author,
        authorName,
        userGroup,
        shareToken,
        sessionUnlimited,
        theme,
        reactions,
        isPaused,
        deactivatedMenuItems,
        labels,
        descriptions,
      } = form;
      const updatedSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link,
        description,
        author,
        authorName,
        userGroup: theme ?? userGroup,
      });
      updatedSession.deactivatedMenuItems = deactivatedMenuItems;
      updatedSession.labels = labels;
      updatedSession.descriptions = descriptions;
      updatedSession.isPaused = isPaused;
      updatedSession.reactions = reactions;
      updatedSession.shareToken = shareToken;
      const sessionId = getters['getCurrentSession'].shareToken;

      // TODO: Reset sessionSentReportStatus to pending

      // DB Initialization
      const db = firebase.firestore();
      const presentersCollection = db.collection('presenters');
      const sessionCollection = db.collection('sessions');

      if (sessionId) {
        await sessionCollection
          .doc(sessionId)
          .set({ resources: { infoPage: { description } } }, { merge: true });
        // await presentersCollection
        //   .doc(author)
        //   .collection('sessions')
        //   .doc(sessionId)
        //   .update(updatedSession.toJSON());
      }
    },
    async updateActiveItems({ getters }, { labels }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        ref.set(
          { resources: { global: { navItems: labels } } },
          { merge: true }
        );
      }
    },
    async updateReactionSet({ getters }, { reactionSet }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        let enabledReactions = [
          'thumbsUp',
          'clap',
          'party',
          'sad',
          'heart',
          'rocket',
        ];
        if (reactionSet === 'CUSTOM') {
          enabledReactions = [
            'leaf',
            'concertHouse',
            'beer',
            'digitalAudience',
          ];
        }
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        ref.set(
          { resources: { reactionsPage: { enabledReactions, reactionSet } } },
          { merge: true }
        );
      }
    },

    async resetReactions({ getters, dispatch }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();

        const newReactionRef = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('reactions')
          .add({});

        const ref = db.collection('sessions').doc(sessionId);
        ref.set(
          {
            resources: {
              reactionsPage: { reactionBatchId: newReactionRef.id },
            },
          },
          { merge: true }
        );
        const reactionBatchId = newReactionRef.id;
        dispatch('bindReactions', reactionBatchId);
      }
    },

    async checkForLastSession({ rootGetters }) {
      const user = rootGetters['auth/getUser'];
      if (user.data) {
        if (user.data.uid && !user.data.isAnonymous) {
          router.replace({ name: 'Presenter Home' });
        }
      }
    },
    async fetchPresenterSession({ dispatch, commit, rootGetters }, shareToken) {
      const db = firebase.firestore();

      const sessionResponse = await db
        .collection('sessions')
        .doc(shareToken)
        .get();

      if (!sessionResponse) {
        // User has no access to the resource or resource does not exist
        commit('SET_CURRENT_SESSION', null);
        router.replace({ name: 'Presenter Home' });
      } else {
        const session = sessionResponse.data() ?? {};
        const sessionId: string = session.shareToken;
        session.id = sessionId;

        commit('SET_CURRENT_SESSION', session);

        if (process.env.NODE_ENV === 'development') {
          console.log(session.shareToken);
        }

        dispatch('bindCurrentSession', sessionId);
        dispatch('bindPresenterPolls', { sessionId });
        dispatch('bindReactions');
        dispatch('bindPollCategories', {
          sessionId,
        });
        dispatch('bindPollAnswers', {
          sessionId,
        });
        dispatch('viewer/bindSessionViewers', { sessionId }, { root: true });
        dispatch('questions/bindQuestions', { sessionId }, { root: true });
        dispatch(
          'questions/bindQuestionColumns',
          { sessionId },
          { root: true }
        );
        dispatch('bindPresenterWordcloud');
      }
    },
    bindCurrentSession: firestoreAction(
      async ({ bindFirestoreRef }, sessionId) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        await bindFirestoreRef('currentSession', ref, {
          wait: true,
        });
      }
    ),
    bindAllSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').where('author', '==', userId);
        await bindFirestoreRef('sessions', ref, {
          wait: true,
        });
      }
    ),
    bindUnlimitedSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .where('author', '==', userId)
          .where('sessionUnlimited', '==', true)
          .orderBy('startAt', 'desc');
        await bindFirestoreRef('unlimitedSessions', ref, {
          wait: true,
        });
      }
    ),
    bindSessionsRef: firestoreAction(async ({ bindFirestoreRef }, userId) => {
      const db = firebase.firestore();
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      const ref = db
        .collection('sessions')
        .where('author', '==', userId)
        .where('endAt', '>', now)
        .orderBy('endAt', 'desc')
        .orderBy('startAt', 'desc');
      await bindFirestoreRef('sessions', ref, {
        wait: true,
      });
    }),
    bindPastSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const now = firebase.firestore.Timestamp.fromDate(new Date());
        const ref = db
          .collection('sessions')
          .where('author', '==', userId)
          .where('endAt', '<=', now)
          .orderBy('endAt', 'desc')
          .orderBy('startAt', 'desc');
        await bindFirestoreRef('sessions', ref, {
          wait: true,
        });
      }
    ),
    bindReactions: firestoreAction(
      async ({ bindFirestoreRef, getters }, reactionBatchId?) => {
        const sessionId = getters['getCurrentSession'].shareToken;

        let batchId = reactionBatchId;
        if (!reactionBatchId) {
          batchId =
            getters['getCurrentSession'].resources.reactionsPage
              .reactionBatchId;
        }

        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('reactions')
          .doc(batchId);
        await bindFirestoreRef('reactions', ref, {
          wait: true,
        });
      }
    ),
    async cancelSession(_, sessionId) {
      // Stop current session
      const db = firebase.firestore();
      if (sessionId) {
        const sessionsCollection = db.collection('sessions');
        await sessionsCollection
          .doc(sessionId)
          .update({ endAt: new Date(), sessionUnlimited: false });
      }
      window.location.reload();
    },
    async removeSession({ rootGetters }, { sessionId, sessionPath }) {
      const db = firebase.firestore();
      const userId = rootGetters['auth/getUserId'];
      const presentersCollection = db.collection('presenters');
      const sessionsCollection = db.collection('sessions');

      await sessionsCollection.doc(sessionId).delete();
      await presentersCollection
        .doc(userId)
        .collection('sessions')
        .doc(sessionId)
        .delete();

      if (
        sessionPath !== '/presenter/home' &&
        sessionPath !== '/presenter/sessions/past'
      ) {
        router.push({ name: 'Presenter Home' });
      }
    },

    async pauseSession(_, { sessionId, state }) {
      // Stop current session
      const db = firebase.firestore();
      if (sessionId) {
        const sessionsCollection = db.collection('sessions');
        await sessionsCollection
          .doc(sessionId)
          .set({ isPaused: state }, { merge: true });
      }
    },
    async updateSessionLabel({ getters, commit }, { label, labelId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`labels.${labelId}`]: label });
        commit('REMOVE_SESSION_LABEL', labelId);
      }
    },
    async updateSessionDescription(
      { getters, commit },
      { description, descriptionId }
    ) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`descriptions.${descriptionId}`]: description });
        commit('REMOVE_SESSION_DESCRIPTION', descriptionId);
      }
    },
    async updateSessionButtonLabel(
      { getters, commit },
      { buttonLabel, buttonId }
    ) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`buttonLabels.${buttonId}`]: buttonLabel });
        commit('REMOVE_SESSION_BUTTON_LABEL', buttonId);
      }
    },
    bindTimelineData: firestoreAction(
      async ({ bindFirestoreRef }, sessionId) => {
        const db = firebase.firestore();

        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('timeline');
        await bindFirestoreRef('timelineData', ref, {
          wait: true,
        });
      }
    ),
    /**************** Polls **********/
    bindPresenterPolls: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .orderBy('order', 'asc');
        await bindFirestoreRef('sessionPolls', ref, {
          wait: true,
        });
      }
    ),
    async fetchPollResults({ commit }, shareToken) {
      commit('SET_LOADING_STATE', true);
      const db = firebase.firestore();
      const polls = await db
        .collection('sessions')
        .doc(shareToken)
        .collection('polls')
        .get();

      let url = `https://europe-west3-reaction-link-dev-160b1.cloudfunctions.net/evaluateChoicePolls?sessionID=${shareToken}`;
      for (const poll of polls.docs) {
        url += `&pollsIDs=${poll.id}`;
      }

      const res: Response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resJSON = await res.json();
      if (resJSON.CHOICE_POLLS) {
        // commit(
        //   'SET_SLIDERS_ANSWERS',
        //   resJSON.SLIDER_POLLS.summary.statsByPollId
        // );
        commit('SET_CHOICES_ANSWERS', resJSON.CHOICE_POLLS.choicesByPollId);
        commit('SET_LOADING_STATE', false);
      }
    },
    bindPollCategories: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('pollCategories');
        await bindFirestoreRef('pollCategories', ref, {
          wait: true,
        });
      }
    ),
    // bindPollAnswers: firestoreAction(
    //   async ({ bindFirestoreRef }, { sessionId }) => {
    //     const db = firebase.firestore();
    //     const ref = db
    //       .collection('sessions')
    //       .doc(sessionId)
    //       .collection('answers');
    //     await bindFirestoreRef('pollAnswers', ref, {
    //       wait: true,
    //     });
    //   }
    // ),
    async createPoll({ rootGetters, getters }, { form }) {
      const {
        title,
        answerOptions,
        defaultValue,
        type,
        description,
        choices,
        visible,
        isPaused,
      } = form;

      const db = firebase.firestore();
      const sessionId = getters['getCurrentSession'].shareToken;
      const { uid } = rootGetters['auth/getUser'].data;
      const countPolls = getters['getSessionPolls'].length;

      let newPoll;
      if (type === 'SLIDER') {
        newPoll = PresenterPoll.fromDict({
          title,
          description,
          type,
          answerOptions: {
            min: parseInt(answerOptions.min),
            max: parseInt(answerOptions.max),
            step: 100,
          },
          defaultValue: parseInt(defaultValue),
          author: uid,
          visible,
          isPaused,
        });
      } else {
        newPoll = PresenterPoll.fromDict({
          title,
          description,
          type,
          answerOptions: {
            choices,
            choiceAmount: 1,
          },
          author: uid,
          visible,
          isPaused,
          order: countPolls + 1,
        });
      }

      await db
        .collection('sessions')
        .doc(sessionId)
        .collection('polls')
        .add(newPoll.toJSON());

      // const pollId = pollRef.id;
      // pollCategories.map(async (c: string) => {
      //   await db
      //     .collection('sessions')
      //     .doc(sessionId)
      //     .collection('pollCategories')
      //     .doc(c)
      //     .update({ polls: firebase.firestore.FieldValue.arrayUnion(pollId) });
      // });
    },
    async updatePoll({ rootGetters, getters }, { form, pollId }) {
      const {
        title,
        answerOptions,
        defaultValue,
        type,
        description,
        choices,
        visible,
        isPaused,
      } = form;

      const { uid } = rootGetters['auth/getUser'].data;

      let updatedPoll;
      if (type === 'SLIDER') {
        updatedPoll = PresenterPoll.fromDict({
          title,
          description,
          type,
          answerOptions: {
            min: parseInt(answerOptions.min),
            max: parseInt(answerOptions.max),
            step: 100,
          },
          defaultValue: parseInt(defaultValue),
          author: uid,
          visible,
          isPaused,
        });
      } else {
        updatedPoll = PresenterPoll.fromDict({
          title,
          description,
          type,
          answerOptions: {
            choices,
            choiceAmount: 1,
          },
          author: uid,
          visible,
          isPaused,
        });
      }

      const db = firebase.firestore();
      const sessionId = getters['getCurrentSession'].shareToken;

      if (sessionId && pollId) {
        db.collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update(updatedPoll.toJSON());

        // const categoryResults = await db
        //   .collection('sessions')
        //   .doc(sessionId)
        //   .collection('pollCategories')
        //   .where('polls', 'array-contains', pollId)
        //   .get();
        // categoryResults.docs.map(async (c) => {
        //   c.ref.update({
        //     polls: c.data().polls.filter((id: string) => id !== pollId),
        //   });
        // });

        // pollCategories.map(async (c: string) => {
        //   await db
        //     .collection('sessions')
        //     .doc(sessionId)
        //     .collection('pollCategories')
        //     .doc(c)
        //     .update({
        //       polls: firebase.firestore.FieldValue.arrayUnion(pollId),
        //     });
        // });
      }
    },
    async resetPoll({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        // Remove all poll answers
        const pollAnswersDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('answers')
          .get();
        pollAnswersDocs.docs.map(async (pollAnswerDoc) => {
          await pollAnswerDoc.ref.delete();
        });

        // Clean answers
        const answersDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('answers')
          .get();

        answersDocs.docs.map(async (answerDoc) => {
          if (answerDoc.data()[pollId]) {
            await answerDoc.ref.update({
              [pollId]: firebase.firestore.FieldValue.delete(),
            });
          }
        });
      }
    },
    async removePoll({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        // Clean poll && poll answers
        const db = firebase.firestore();
        const pollAnswers = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('answers')
          .get();

        pollAnswers.docs.map(async (answer) => {
          answer.ref.delete();
        });

        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .delete();

        // Clean answers
        const answersDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('answers')
          .get();

        answersDocs.docs.map(async (answerDoc) => {
          if (answerDoc.data()[pollId]) {
            await answerDoc.ref.update({
              [pollId]: firebase.firestore.FieldValue.delete(),
            });
          }
        });

        //Delete also from category
        // const categoryResults = await db
        //   .collection('sessions')
        //   .doc(sessionId)
        //   .collection('pollCategories')
        //   .where('polls', 'array-contains', pollId)
        //   .get();
        // categoryResults.docs.map(async (c) => {
        //   c.ref.update({
        //     polls: c.data().polls.filter((id: string) => id !== pollId),
        //   });
        // });
      }
    },
    async togglePollVisibility({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ visible: !!state });
      }
    },
    async togglePollAnimation({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ runAnimation: Math.random() });
      }
    },
    async resetPollAnimation({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ resetAnimation: Math.random() });
      }
    },
    async togglePollStatisticsVisibility({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ showPollStatistics: !!state });
      }
    },
    async togglePollPaused({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ isPaused: !!state });
      }
    },
    async updatePollPosition({ getters }, newPollList) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();

        const count = newPollList.length;
        await newPollList.map(async (poll: PresenterPoll, i: number) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(poll.id)
            .update({ position: count - i });
        });
      }
    },
    async togglePollLabelState({ getters }, state) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ hidePollLabels: state });
      }
    },
    async updatePollVoteResultType({ getters }, id) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ voteResultType: id });
      }
    },
    /**************** Wordclouds **********/
    bindPresenterWordcloud: firestoreAction(
      async ({ bindFirestoreRef, getters }) => {
        const sessionId = getters['getCurrentSession'].shareToken;
        const activeWordcloudId =
          getters['getCurrentSession'].resources.wordcloudPage.wordcloudId;
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds')
          .doc(activeWordcloudId);
        await bindFirestoreRef('wordcloud', ref, {
          wait: true,
        });
      }
    ),
    async toggleWordcloudPaused({ getters }, { state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .set(
            {
              resources: { wordcloudPage: { isWordcloudPaused: !!state } },
            },
            { merge: true }
          );
      }
    },
    async resetWordcloud({ getters }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      const currentWordcloudId =
        getters['getCurrentSession'].resources.wordcloudPage.wordcloudId;
      const currentWordcloudTopic =
        getters['getCurrentSession'].resources.wordcloudPage.wordcloudTopic;

      if (sessionId) {
        const db = firebase.firestore();

        //Store wordcloud archive
        await db
          .collection('sessions')
          .doc(sessionId)
          .set(
            {
              resources: {
                wordcloudPage: {
                  wordcloudArchive: {
                    [currentWordcloudId]: currentWordcloudTopic,
                  },
                },
              },
            },
            { merge: true }
          );

        const newWordcloudId = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds')
          .add({});
        if (newWordcloudId.id) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .set(
              {
                resources: {
                  wordcloudPage: { wordcloudId: newWordcloudId.id },
                },
              },
              { merge: true }
            );
        }
      }
    },
    async updateWordcloudTopic({ getters }, { topic }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .set(
            { resources: { wordcloudPage: { wordcloudTopic: topic } } },
            { merge: true }
          );
      }
    },
    /**************** TV Mode **********/
    async fetchTVSession({ dispatch, commit }, shareToken) {
      const db = firebase.firestore();
      const sessionResponse = await db
        .collection('sessions')
        .doc(shareToken)
        .get();
      const session = sessionResponse.data()!;
      const sessionId = sessionResponse.id;

      if (sessionId) {
        session.id = sessionId;
        commit('SET_CURRENT_SESSION', session);

        dispatch('bindCurrentSession', sessionId);
        dispatch(
          'viewer/fetchSessionTheme',
          {
            sessionUserGroup: session.userGroup,
          },
          { root: true }
        );
        dispatch(
          'viewer/bindSessionViewers',
          {
            sessionId,
          },
          { root: true }
        );
        dispatch(
          'questions/bindQuestions',
          {
            sessionId,
          },
          { root: true }
        );
        dispatch(
          'questions/bindQuestionColumns',
          { sessionId },
          { root: true }
        );
        dispatch('bindPresenterPolls', {
          sessionId,
        });
        dispatch('bindReactions');
        dispatch('bindPollCategories', {
          sessionId,
        });
        dispatch('bindPresenterWordcloud');
      }
    },
    async activateTVMode({ getters }, { tvMode, state, pollResults }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        if (state) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .set({ tv: tvMode }, { merge: true });
        } else {
          await db
            .collection('sessions')
            .doc(sessionId)
            .set({ tv: '' }, { merge: true });
        }
        if (pollResults) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .update({ tvResults: pollResults });
        }
      }
    },
    async syncPollResults({ getters }, { pollResults }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        if (pollResults) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .update({ tvResults: pollResults });
        }
      }
    },
    async toggleGreenscreenVisibilitySetting({ getters }, state) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ showGreenscreen: !!state });
      }
    },
    /**************** Session Themes Section **********/
    bindSessionThemes: firestoreAction(async ({ bindFirestoreRef }) => {
      const db = firebase.firestore();
      const ref = db.collection('themes');
      await bindFirestoreRef('sessionThemes', ref, {
        wait: true,
      });
    }),
  },
};

export { PresenterModule };
