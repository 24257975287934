/* eslint @typescript-eslint/camelcase: 0 */
interface UserCredentials {
  username: string;
  password: string;
}

class ViewerQuestion {
  public id: string;
  public title: string;
  public position: number;
  public createdAt: Date;
  public updatedAt: Date;
  public author: string;
  public upvotes: number;
  public isVisible: boolean;
  public initialPosition: number;
  public answer: string;
  public isSelected: boolean;
  public isAnswered: boolean;
  public isBookmarked: boolean;
  public questionerName: string;
  public columnId: string;

  constructor(
    id: string,
    title: string,
    position: number,
    createdAt: Date,
    updatedAt: Date,
    author: string,
    upvotes: number,
    isVisible: boolean,
    initialPosition: number,
    answer: string,
    isSelected: boolean,
    isAnswered: boolean,
    isBookmarked: boolean,
    questionerName: string,
    columnId: string
  ) {
    this.id = id;
    this.position = position;
    this.title = title;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.author = author;
    this.upvotes = upvotes;
    this.isVisible = isVisible;
    this.initialPosition = initialPosition;
    this.answer = answer;
    this.isSelected = isSelected;
    this.isAnswered = isAnswered;
    this.isBookmarked = isBookmarked;
    this.questionerName = questionerName;
    this.columnId = columnId;
  }

  static fromDict(dict: any): ViewerQuestion {
    const {
      title,
      position,
      initialPosition,
      author,
      isVisible,
      questionerName,
    } = dict;

    return new ViewerQuestion(
      '',
      title,
      position,
      new Date(),
      new Date(),
      author,
      0,
      isVisible,
      initialPosition,
      '',
      false,
      false,
      false,
      questionerName || '',
      'default'
    );
  }

  toJSON(): any {
    return {
      position: this.position,
      title: this.title,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      author: this.author,
      upvotes: this.upvotes,
      isVisible: this.isVisible,
      initialPosition: this.initialPosition,
      answer: this.answer,
      isSelected: this.isSelected,
      isAnswered: this.isAnswered,
      isBookmarked: this.isBookmarked,
      questionerName: this.questionerName,
      columnId: this.columnId,
    };
  }
}

enum PollType {
  Slider = 'SLIDER',
  SingleChoice = 'CHOICE',
}

class PresenterPoll {
  public id: string;
  public title: string;
  public description: { text: string };
  public type: PollType;
  public createdAt: Date;
  public updatedAt: Date;
  public author: string;
  public answerOptions: {
    min?: number;
    max?: number;
    step?: number;
    choices?: { id: string; value: string }[];
    choiceAmount?: number;
  };
  public defaultValue?: number;
  public visible: boolean;
  public isPaused: boolean;
  public order: number;

  constructor(
    id: string,
    title: string,
    description: { text: string },
    type: PollType,
    createdAt: Date,
    updatedAt: Date,
    author: string,
    answerOptions: {
      min?: number;
      max?: number;
      step?: number;
      choices?: { id: string; value: string }[];
      choiceAmount?: number;
    },
    defaultValue: number,
    visible: boolean,
    isPaused: boolean,
    order: number
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.type = type;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.author = author;
    this.answerOptions = answerOptions;
    this.defaultValue = defaultValue;
    this.visible = visible;
    this.isPaused = isPaused;
    this.order = order;
  }

  static fromDict(dict: any): PresenterPoll {
    const {
      title,
      description,
      type,
      author,
      answerOptions,
      defaultValue,
      visible,
      order,
    } = dict;

    return new PresenterPoll(
      '',
      title,
      { text: description },
      type,
      new Date(),
      new Date(),
      author,
      answerOptions,
      defaultValue ?? null,
      visible ?? false,
      false,
      order || 0
    );
  }

  toJSON(): any {
    return {
      title: this.title,
      description: this.description,
      type: this.type,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      author: this.author,
      answerOptions: this.answerOptions,
      defaultValue: this.defaultValue,
      visible: this.visible,
      isPaused: this.isPaused,
      order: this.order,
    };
  }
}

export { UserCredentials, ViewerQuestion, PresenterPoll, PollType };
