import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import { i18n } from './lang/i18n.config';
import { makeServer } from './api/mock-server';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import VCalendar from 'v-calendar';
import VueWordCloud from 'vuewordcloud';
import Clipboard from 'v-clipboard';
import linkify from 'vue-linkify';
import VueToast from 'vue-toast-notification';
import longpress from 'vue-long-press-directive';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import '@/assets/css/tailwind.css';
import 'vue-toast-notification/dist/theme-sugar.css';

// Init mock api
if (
  process.env.NODE_ENV === 'development' &&
  process.env.VUE_APP_FAKE_SERVER_START === 'true'
) {
  makeServer();
}

// Init Sentry on production environment
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn:
      'https://2ece82294f6b46be8d42826a254db0a8@o534399.ingest.sentry.io/5653584',
    integrations: [new Integrations.BrowserTracing()],
    logErrors: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

import { Swiper as SwiperClass, Pagination } from 'swiper';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

// import style
import 'swiper/swiper-bundle.min.css';

// Vue.use(VueReCaptcha, {
//   siteKey: process.env.VUE_APP_CAPTCHA_KEY,
//   loaderOptions: { autoHideBadge: true },
// });

SwiperClass.use([Pagination]);
Vue.use(getAwesomeSwiper(SwiperClass));

Vue.use(VueToast);
Vue.use(longpress, { duration: 1000 });

Vue.directive('linkified', linkify);
Vue.use(Clipboard);

Vue.use(VCalendar, { locale: 'de', firstDayOfWeek: 0 });
Vue.component(VueWordCloud.name, VueWordCloud);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: 'AIzaSyANza8eWdUUNlUECgHZhfh_rwxX1iHsY_4',
  authDomain: 'reaction-link-dev-160b1.firebaseapp.com',
  projectId: 'reaction-link-dev-160b1',
  storageBucket: 'reaction-link-dev-160b1.appspot.com',
  messagingSenderId: '626347428604',
  appId: '1:626347428604:web:5014ffb4f5501495f2a579',
};
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
    created() {
      if (firebaseUser) {
        store.dispatch('auth/autoSignIn', firebaseUser);
      }
    },
  }).$mount('#app');
  unsubscribe();
});
