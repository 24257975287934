import Vue from 'vue';
import VueRouter from 'vue-router';
import * as firebase from 'firebase/app';
import 'firebase/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/presenter/home',
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/start',
  //   name: 'Start',
  //   component: () =>
  //     import(/* webpackChunkName: "Start" */ '../views/Start.vue'),
  // },
  /* For presenters */
  {
    path: '/presenter',
    component: () =>
      import(
        /* webpackChunkName: "PresenterRouterView" */ '../views/Presenter/RouterView.vue'
      ),
    children: [
      {
        path: 'signin',
        name: 'Presenter Signin',
        component: () =>
          import(
            /* webpackChunkName: "PresenterSignin" */ '../views/Presenter/Auth/Signin.vue'
          ),
      },
      // {
      //   path: 'signup',
      //   name: 'Presenter Signup',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "PresenterSignup" */ '../views/Presenter/Auth/Signup.vue'
      //     ),
      // },
      // {
      //   path: 'onboarding',
      //   name: 'Presenter Onboarding',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "PresenterOnboarding" */ '../views/Presenter/Auth/Signup.vue'
      //     ),
      //   meta: {
      //     requiresAuth: true,
      //   },
      //   children: [
      //     {
      //       path: 'first-step',
      //       name: 'Presenter Onboarding Step 1',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "PresenterOnboardingStep1" */ '../views/Presenter/Auth/Signup.vue'
      //         ),
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //     {
      //       path: 'second-step',
      //       name: 'Presenter Onboarding Step 2',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "PresenterOnboardingStep2" */ '../views/Presenter/Auth/Signup.vue'
      //         ),
      //       meta: {
      //         requiresAuth: true,
      //       },
      //     },
      //   ],
      // },
      {
        path: 'home',
        name: 'Presenter Home',
        component: () =>
          import(
            /* webpackChunkName: "PresenterHome" */ '../views/Presenter/Home.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'sessions/new',
        name: 'New Presenter Session',
        component: () =>
          import(
            /* webpackChunkName: "NewPresenterSession" */ '../views/Presenter/NewSession.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'sessions/past',
        name: 'Past Sessions',
        component: () =>
          import(
            /* webpackChunkName: "PastSessions" */ '../views/Presenter/PastSessions.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      // /* For external tv */
      // {
      //   path: 'sessions/:presenterSessionId/tv',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "PastSessions" */ '../views/Presenter/TV.vue'
      //     ),
      //   meta: {
      //     requiresAuth: false,
      //   },
      // },
      {
        path: 'sessions/:presenterSessionId/edit',
        name: 'Presenter Session Edit',
        component: () =>
          import(
            /* webpackChunkName: "PresenterSessionEdit" */ '../views/Presenter/EditSession.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':presenterSessionId/edit',
        name: 'Edit Session From Card',
        component: () =>
          import(
            /* webpackChunkName: "PresenterSessionEdit" */ '../views/Presenter/EditSession.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':presenterSessionId/duplicate',
        name: 'Duplicate Session',
        component: () =>
          import(
            /* webpackChunkName: "PresenterSessionEdit" */ '../views/Presenter/DuplicateSession.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'sessions/:presenterSessionId',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "PresenterSession" */ '../views/Presenter/Session.vue'
          ),
        children: [
          {
            path: '',
            name: 'Presenter Session Stats',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionStats" */ '../views/Presenter/Session/Stats.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          // {
          //   path: 'analytics',
          //   name: 'Presenter Session Analytics',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "PresenterSessionAnalytics" */ '../views/Presenter/Session/Analytics.vue'
          //     ),
          //   meta: {
          //     requiresAuth: true,
          //   },
          // },
          {
            path: 'questions/settings',
            name: 'Settings',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionQuestionSettings" */ '../views/Presenter/Session/Questions/Settings.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'questions',
            name: 'Presenter Session Questions',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionQuestion" */ '../views/Presenter/Session/Questions.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'polls/settings',
            name: 'Presenter Session Poll Settings',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionPollsSettings" */ '../views/Presenter/Session/Polls/Settings.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'polls',
            name: 'Presenter Session Polls',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionPolls" */ '../views/Presenter/Session/Polls.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'polls/new',
            name: 'Presenter Session New Poll',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionNewPoll" */ '../views/Presenter/Session/NewPoll.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'poll/:pollId/edit',
            name: 'Presenter Session Edit Poll',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionEditPoll" */ '../views/Presenter/Session/EditPoll.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'wordclouds',
            name: 'Presenter Session Wordclouds',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionWordclouds" */ '../views/Presenter/Session/Wordclouds.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'report',
            name: 'Presenter Session Report',
            component: () =>
              import(
                /* webpackChunkName: "PresenterSessionReport" */ '../views/Presenter/Session/Report.vue'
              ),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: '',
                redirect: '1',
              },
              {
                path: '1',
                name: 'Presenter Session Report Home',
                component: () =>
                  import(
                    /* webpackChunkName: "PresenterSessionReport" */ '../views/Presenter/Session/Report/Home.vue'
                  ),
              },
              {
                path: '2',
                name: 'Presenter Session Report Visible Questions',
                component: () =>
                  import(
                    /* webpackChunkName: "PresenterSessionReport" */ '../views/Presenter/Session/Report/VisibleQuestions.vue'
                  ),
              },
              {
                path: '3',
                name: 'Presenter Session Report Invisible Questions',
                component: () =>
                  import(
                    /* webpackChunkName: "PresenterSessionReport" */ '../views/Presenter/Session/Report/InvisibleQuestions.vue'
                  ),
              },
              {
                path: '4',
                name: 'Presenter Session Report Polls',
                component: () =>
                  import(
                    /* webpackChunkName: "PresenterSessionReport" */ '../views/Presenter/Session/Report/Polls.vue'
                  ),
              },
              {
                path: '5',
                name: 'Presenter Session Report Wordcloud',
                component: () =>
                  import(
                    /* webpackChunkName: "PresenterSessionReport" */ '../views/Presenter/Session/Report/Wordcloud.vue'
                  ),
              },
            ],
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () =>
          import(
            /* webpackChunkName: "PresenterProfile" */ '../views/Presenter/Profile.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/presenter/:uid',
    component: () =>
      import(
        /* webpackChunkName: "PresenterRouterView" */ '../views/Presenter/RouterView.vue'
      ),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'preferences',
        name: 'Presenter Preferences',
        component: () =>
          import(
            /* webpackChunkName: "PresenterPreferences" */ '../views/Presenter/Preferences.vue'
          ),
        children: [
          {
            path: 'email',
            name: 'Presenter Preferences Email',
            component: () =>
              import(
                /* webpackChunkName: "PresenterPreferencesEmail" */ '../views/Presenter/Preferences/Email.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = firebase.auth().currentUser;

  if (to.path == '/presenter/signout') next({ name: 'Presenter Signin' });
  if (to.matched.some((route) => route.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'Presenter Signin', query: { redirect: to.path } });
  } else {
    next();
  }
});

// Always force to scroll to top of view
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export { router };
